<script setup>
const { $notify } = useNuxtApp()

const openDialog = ref(false)

const props = defineProps({ post: Object })
let localPost = ref({})
const emit = defineEmits(['update'])

// TODO: could be improved
async function open (u) {
  openDialog.value = true
  localPost.value = {...u}
}
defineExpose({ open })

async function editPost () {
  const tagsId = localPost.value.tags.filter(t => t?.id).map(t => ({ id: t.id }))
  const tagsName = localPost.value.tags.filter(t => !t?.id).map(t => ({ name: t }))
  try {
    const ret = await $fetch(`/api/post/detail/${localPost.value.id}`, { method: 'PATCH', body: { 
      title: localPost.value.title,
      summary: localPost.value.summary,
      tags: {
        set: tagsId,
        create: tagsName
      }
    }})
    emit('update', false)
    $notify('Post updated!', 'success')
    openDialog.value = false
  } catch (e) {
    $notify(e, 'error')
    console.error(e)
  }
}

// tags
const selectedTags = ref([])
const searchTag = ref('')
const query = computed(() => `/api/tag?query=${searchTag.value}&limit=10`,)
const { data: tags = [], pending: loadingTags } = await useLazyFetch(query)

</script>
<template>
  <v-dialog :model-value="openDialog" width="700">
    <v-card> 
      <v-card-title>{{ $t('post.edit') }}</v-card-title>
      <!-- <v-card-subtitle>{{ $t('post.add_custom_post_description') }} <br/> {{ post.url }}</v-card-subtitle> -->
      <!-- <v-card-text v-if="!ret.pending && ret.error">
        <v-alert icon='mdi-fire' color='error' :text="ret.error.toString()" ></v-alert>
      </v-card-text> -->
      <v-card-text>
        <!-- <Post :post="post" preview/><br/> -->
        <v-text-field v-model="localPost.title" label="Title"/>
        <v-textarea v-model="localPost.summary" label="Summary" />
        <v-combobox v-model="localPost.tags" multiple
          v-model:search='searchTag'
          persistent-hint
          :hint="$t('post.add_custom_post_tag_description')"
          :items="tags" :loading="loadingTags" item-value='id' item-title='name'
          :label="$t('Tags')" chips closable-chips hide-no-data />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="warning" @click="openDialog = false">{{ $t('Close')}}</v-btn>
        <v-btn color="success" @click="editPost">{{ $t('Save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
